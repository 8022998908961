/* TABLE STYLES */
.ui-table {
  box-sizing: border-box;
  display: block;
  vertical-align: middle;
  font-family: 'inter', sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  text-align: left;
  font-size: 10px;
  background: #ffffff;
}

.ui-table__thead {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
}

.ui-table__thead--is-mobile {
  display: none;
}

.ui-table__tbody {
  display: flex;
  flex-direction: column;
}

.ui-table__tfoot {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #eaeaea;
}

.ui-table__tfoot--is-mobile {
  display: none;
}

.ui-table__tr {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-top: 1px solid #eaeaea;
  position: relative;
}

.ui-table--is-mobile .ui-table__tr {
  flex-direction: column;
}

.ui-table__th {
  display: block;
  vertical-align: middle;
  box-sizing: border-box;
  flex: 1 1 auto;
  padding: 20px;
  font-weight: 500;
  font-size: 1.1em;
  color: #333333;
  background: transparent;
}

.ui-table__td {
  display: block;
  box-sizing: border-box;
  flex: 1 1 auto;
  vertical-align: middle;
  padding: 20px;
  font-size: 1.4em;
  font-weight: 400;
  color: #333333;
  background: transparent;
}

.ui-table__td--is-mobile {
  padding: 10px;
  width: 100%;
}

.ui-table__td:first-child {
  border-top: none;
}

.ui-table__td__title {
  display: block;
  font-weight: 500;
  font-size: 0.7em;
  color: #333333;
}

.ui-table__tfoot .ui-table__td {
  font-size: 1.1em;
  font-weight: 500;
}
