$use-px: false; // Defines if units should be REM or PX
$a: true; // Defines if main blocks should render
$b: false; // Defines if ie blocks should render
$debug: false; // Defines if debug styles should render

@import 'dependency'; // includes all corporate dependencies

// Main dependencies
// =================

.page {
  background-color: #f6f7fb;
  min-height: calc(100vh - 80px);
}

.form__multiselect-wrapper {
  // multiselect wrapped with row and column's not 100% width
  width: 100%;
}