@layer components {
  .secondary-nav__container {
    @apply flex w-full px-5 shadow-secondary-navigation;
  }
  .secondary-nav__nav {
    @apply text-lush-charcoal tracking-[0.1667em] mx-auto flex w-full max-w-1100 space-x-5 overflow-x-auto px-2 text-center text-xs uppercase md:space-x-10;
  }
  .secondary-nav__links {
    @apply px-1.5 py-7 hover:cursor-pointer;
    &--active {
      @apply text-lush-dark-gray border-b-lush-dark-gray border-b-4 border-solid;
    }

    &--inactive {
      @apply hover:opacity-60 hover:transition-opacity hover:duration-300;
    }
  }
}
