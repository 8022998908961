@layer components {
  .btn {
    @apply font-inter text-lush-xs leading-4 font-medium tracking-[2px] border border-solid rounded-none text-center uppercase py-4 px-7;
    @apply hover:opacity-60 transition-opacity hover:duration-300;
    @apply focus:outline-none focus:border-lush-blue focus:shadow-btn-primary;
  }

  .btn:disabled {
    @apply opacity-60;
  }
  .btn-primary {
    @apply bg-black border-black text-white;
  }

  .btn-secondary {
    @apply bg-white border-gray-300 text-black;
    @apply hover:bg-gray-100 hover:border-gray-500;
  }
}
