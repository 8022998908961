.till-overlay {
  .modal__header {
    padding: 2rem 2.5rem;
    text-align: center;
    .modal__subheading {
      display: none;
    }
  }
  .modal__close {
    right: 2.5rem;
    top: 1rem;
  }
  .modal__body {
    padding: 0 0 3rem;
  }
  .modal__wrapper {
    max-width: 56rem;
    width: 100%;
    left: 0;
    margin-left: 0;
    @media only screen and (min-width: 561px) {
      margin-left: -28rem;
      left: 50%;
    }
  }
}

.till-overlay__filter {
  padding: 1.5rem 2.5rem;
  border-bottom: 1px solid $lush-black-8;
  text-align: center;
  .select--inline select {
    font-size: 1.7rem;
  }
}

.till-overlay__filter-text {
  font-size: 1.7rem;
  line-height: 1;
  display: inline-block;
  margin: 0 0.5em 0 0;
}

.till-overlay__content {
  margin: 0;
  padding: 4rem 0;
  font-size: 2.3rem;
  line-height: calc(32 / 23);
}

.till-overlay__form {
  margin-top: 4rem;
  .row {
    margin: 0 auto 1rem;
    padding: 0 3rem;
    max-width: 340px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.till-overlay__form-label {
  display: inline-block;
  width: 6rem;
  text-align: left;
  padding-top: 0.65em;
}

.till-overlay__form-input {
  min-height: 50px;
}

.till-overlay__form-input {
  width: calc(100% - 50px);
}

.till-overlay__form-total {
  position: relative;
  .currency-block {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background: $lush-black-8;
  }
}

.till-overlay__form-submit {
  margin-top: 1rem;
  .button {
    width: 100%;
  }
}

.till-overlay__float-field {
  width: 100%;
  height: 50px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1.4rem;
  font-weight: 700;
  border: 1px solid $lush-black-80;
}

.till-overlay__plus-button,
.till-overlay__minus-button {
  position: absolute;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  cursor: pointer;
}


.till-overlay__plus-button {
  right: 0;
  left: auto;
}