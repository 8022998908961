@font-face {
  font-family: 'inter';
  src: url('/assets/fonts/inter/Inter-Regular.ttf');

  font-weight: 400;
}

@font-face {
  font-family: 'LushHandwritten';
  src: url('/assets/fonts/handwritten/lushhandwritten_wlat_bd-webfont.eot');
  src: url('/assets/fonts/handwritten/lushhandwritten_wlat_bd-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/handwritten/lushHandwritten_wlat_bd-webfont.woff2') format('woff2'),
    url('/assets/fonts/handwritten/lushHandwritten_wlat_bd-webfont.woff') format('woff'),
    url('/assets/fonts/handwritten/lushHandwritten_wlat_bd-webfont.ttf') format('truetype'),
    url('/assets/fonts/handwritten/lushhandwritten_wlat_bd-webfont.svg#lush_handwritten_wlatbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
